import React from "react";
import TagManager from "react-gtm-module";
import Navi from "../components/navi.js";
import Footer from "../components/organisms/footer/footer.js";
import SEO from "../plugins/seo/index.js";
import { RenderComponent } from "../hooks/components.js";
import { Helmet } from "react-helmet";

// Override default console.log function in production
if (process.env.NODE_ENV === "production") {
   console.log = function () {};
}

const tagManagerArgs = {
   gtmId: "GTM-M6QJGRH",
};
class StoryblokEntry extends React.Component {
   static getDerivedStateFromProps(props, state) {
      if (state.story.uuid === props.pageContext.story.uuid) {
         return null;
      }

      return StoryblokEntry.prepareStory(props);
   }

   static prepareStory(props) {
      const story = Object.assign({}, props.pageContext.story);
      const globalNavi = Object.assign({}, props.pageContext.globalNavi);

      story.content = JSON.parse(story.content);
      globalNavi.content = JSON.parse(globalNavi.content);

      return { story, globalNavi };
   }

   constructor(props) {
      super(props);

      this.state = StoryblokEntry.prepareStory(props);
   }

   render() {
      let content = this.state.story.content;
      let globalNavi = this.state.globalNavi.content;
      let seo = {
         default: this.state.story,
         data: this.state.story.content.seo,
         featured_image: this.state.story.content.featured_image,
         robots: this.state.story.content.seo_robots,
         location: this.props.location,
      };

      return (
         <div>
            {typeof window !== "undefined" && (
               <Helmet>
                  <script id="mcjs" dangerouslySetInnerHTML={{ __html: `!function(c,h,i,m,p){((m = c.createElement(h)), (p = c.getElementsByTagName(h)[0]), (m.async = 1), (m.src = i), p.parentNode.insertBefore(m, p))}(document,"script","https://chimpstatic.com/mcjs-connected/js/users/0ce22e87d7a5c8e6c026356ae/93cd75ed884198a49f49a98e5.js");` }}></script>
               </Helmet>
            )}

            <SEO blok={seo} />

            {process.env.NODE_ENV !== "development" && typeof window !== "undefined" && TagManager.initialize(tagManagerArgs)}

            <a href="#main" className="bg-clip-border absolute h-0 w-0 overflow-hidden focus:left-6 focus:top-6 z-1000 focus:bg-white focus:px-8 focus:py-4 focus:w-auto focus:h-auto">
               Passer au contenu principal
            </a>

            <Navi blok={globalNavi} location={this.props.location}></Navi>

            <RenderComponent blok={content} />

            <Footer />
         </div>
      );
   }
}

export default StoryblokEntry;
