import { graphql, useStaticQuery } from "gatsby";
import { getFixedGatsbyImage } from "gatsby-storyblok-image";
import React from "react";
import Helmet from "react-helmet";

const SEO = ({ blok }) => {
   const { storyblokEntry } = useStaticQuery(graphql`
      {
         storyblokEntry(slug: { eq: "global-navi" }) {
            content
         }
      }
   `);

   const siteInformations = JSON.parse(storyblokEntry.content);

   let meta = [];

   // On vérifie si la page doit être retirer des résultats de recherche
   if (blok.data?.robotsDisallow)
      meta.push({
         name: "robots",
         content: `noindex, nofollow`,
      });

   meta.push({
      name: "author",
      content: "https://www.facebook.com/FactoryLesCygnes/",
   });

   // On prépare le bloc SEO par défaut
   let seoDefault = {
      title: `${blok.data?.featured_title || blok.default.name}${blok.location.pathname !== "/" ? ` | ${siteInformations.site_informations_title}` : ""}`,
      description: blok.data?.featured_description || siteInformations.site_informations_description,
   };

   if (seoDefault.description)
      meta.push({
         name: "description",
         content: seoDefault.description,
      });

   // On prépare les metas OG
   if (blok.data?.og_image || blok.featured_image?.filename)
      meta.push({
         property: `og:image`,
         content: blok.data.og_image || blok.featured_image.filename,
      });

   meta.push(
      {
         property: `og:title`,
         content: blok.data?.og_title || seoDefault.title,
      },
      {
         property: `og:description`,
         content: blok.data?.og_description || seoDefault.description,
      },
      {
         property: `og:type`,
         content: `website`,
      }
   );

   // On prépare les métas Twitter
   if (blok.data?.twitter_image || blok.featured_image?.filename)
      meta.push({
         name: `twitter:image`,
         content: blok.data.twitter_image || blok.featured_image.filename,
      });

   meta.push(
      {
         name: `twitter:card`,
         content: `summary`,
      },
      {
         name: `twitter:title`,
         content: blok.data?.twitter_title || seoDefault.title,
      },
      {
         name: `twitter:description`,
         content: blok.data?.twitter_description || seoDefault.description,
      }
   );

   return (
      <>
         <Helmet
            htmlAttributes={{
               lang: "fr",
            }}
            title={seoDefault.title}
            meta={meta}
         >
            {blok.data?.canonical && <link rel="canonical" href={blok.data.canonical} />}
            {/* PWA */}
            {/* <link rel="preconnect" href="https://storage.googleapis.com" crossorigin /> */}
            {/* <script type="module">import '/pwa-update.js'; const el = document.createElement('pwa-update'); el.setAttribute('swpath', '/pwabuilder-sw.js'); document.body.appendChild(el);</script> */}
         </Helmet>

         {console.log(siteInformations)}

         {siteInformations.site_informations_favicon && (
            <Helmet>
               <link
                  rel="apple-touch-icon"
                  sizes="180x180"
                  href={
                     getFixedGatsbyImage(siteInformations.site_informations_favicon, {
                        width: 180,
                     }).src
                  }
               />
               <link
                  rel="icon"
                  type="image/png"
                  sizes="32x32"
                  href={
                     getFixedGatsbyImage(siteInformations.site_informations_favicon, {
                        width: 32,
                     }).src
                  }
               />
               <link
                  rel="icon"
                  type="image/png"
                  sizes="16x16"
                  href={
                     getFixedGatsbyImage(siteInformations.site_informations_favicon, {
                        width: 16,
                     }).src
                  }
               />
               <link
                  rel="shortcut icon"
                  href={
                     getFixedGatsbyImage(siteInformations.site_informations_favicon, {
                        width: 32,
                     }).src
                  }
               />
            </Helmet>
         )}
      </>
   );
};

export default SEO;
